import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const emojiService = {
    get, gets, add, update, statusChange
};

async function get(request = {}) {
    return await getInstance(endPoint.emojiGet, request);
}

async function gets(request = {}) {
    return await getInstance(endPoint.emojiGets, request);
}

async function add(request = {}) {
    return await getInstance(endPoint.emojiAdd, request);
}

async function update(request = {}) {
    return await getInstance(endPoint.emojiUpdate, request);
}

async function statusChange(request = {}) {
    return await getInstance(endPoint.emojiStatusChange, request);
}
