<script>


export default {
  name: 'ToolStatusDisplay',
  props: {
    status: Number,
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {
  },
  components: {
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <span v-if="status === 1" class="badge bg-secondary">Draft</span>
      <span v-if="status === 2" class="badge bg-success">Published</span>
      <span v-if="status === 4" class="badge bg-danger">UnPublish</span>
    </div>
  </div>
</template>
