<script>

export default {
  name: 'Pagination',
  props: {
    pageIndex: Number,
    pageSize: Number,
    totalRow: Number,
    onSelectPageFunc: Function,
  },
  data() {
    return {
      pages: [],
      displayTotalRow: 0
    };
  },
  components: {
  },
  watch: {},
  mounted() {
    this.onInitPagination();
  },
  methods: {
    getTotalPage() {
      return Math.ceil(this.totalRow / this.pageSize)
    },
    onSelectPage(page) {
      if (this.pageIndex === page) {
        return;
      }
      this.onInitPagination();
      if (this.onSelectPageFunc) {
        this.onSelectPageFunc(page);
      }
    },
    onInitPagination() {
      this.pages = [];
      const totalPage = this.getTotalPage();
      if (this.totalRow < this.pageSize) {
        this.displayTotalRow = this.totalRow;
      } else {
        let totalRow = (this.pageIndex + 1) * this.pageSize;
        if (totalRow > this.totalRow) {
          totalRow = this.totalRow;
        }
        this.displayTotalRow = totalRow;
      }

      if (totalPage > 0) {
        for (let i = 0; i < totalPage; i++) {
          this.pages.push({
            id: i,
            text: i + 1
          });
        }
      }
    }
  },
};

</script>

<template>
  <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
    <div class="flex-shrink-0">
      <div class="text-muted">
        Showing <span class="fw-semibold">{{ displayTotalRow }}</span> of <span class="fw-semibold">{{ totalRow }}</span> Results
      </div>
    </div>
    <ul class="pagination pagination-separated pagination-sm mb-0">
      <li class="page-item disabled" v-if="pageIndex === 0">
        <a href="#" class="page-link">←</a>
      </li>
      <li class="page-item" v-if="pageIndex > 0">
        <a href="#" @click="onSelectPage(pageIndex - 1)" class="page-link">←</a>
      </li>
      <li v-for="item in pages" :key="item.id" :class="item.id === pageIndex ? 'page-item active' : 'page-item'" >
        <a href="#" class="page-link" @click="onSelectPage(item.id)">{{ item.text }}</a>
      </li>
      <li class="page-item" v-if="getTotalPage() === 0 || pageIndex === getTotalPage() - 1">
        <a href="#" class="page-link disabled">→</a>
      </li>
      <li class="page-item" v-if="getTotalPage() > 0 && pageIndex < getTotalPage() - 1">
        <a href="#" @click="onSelectPage(pageIndex + 1)" class="page-link">→</a>
      </li>
    </ul>
  </div>
</template>
